import React from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { StyledNavLink } from 'shared/components/StyledNavLink';

// @ts-ignore
const Dropdown = ({ closeToggle, children }) => {
	const ref = useDetectClickOutside({ onTriggered: closeToggle });
	return (
		<div className="dropdown" ref={ref}>
			{children?.map((child: any) => {
				const isParentChild = Boolean(child?.children?.length);
				return (
					<StyledNavLink
						{...child?.props}
						key={child.label}
						style={{
							width: '100%',
							margin: '0',
							justifyContent: 'center',
							minHeight: '40px',
						}}
						{...{
							isActive: match => {
								return (
									isParentChild
										? match?.url.includes(child.prefix || '')
										: match?.url === child.link && match?.isExact
								) as boolean;
							},
						}}
						to={child.link}
						onClick={closeToggle}
					>
						{child.icon && (
							<img src={`/images/icons/${child.icon}.svg`} alt={child.icon} />
						)}
						{child.label}
					</StyledNavLink>
				);
			})}
		</div>
	);
};

export default Dropdown;
