import React, { cloneElement } from 'react';

interface Props {
  contexts: JSX.Element[];
}

const ContextComposer: React.FC<Props> = ({ contexts, children }) => (
  <>
    {contexts.reduceRight(
      // tslint:disable-next-line:no-shadowed-variable
      (children, parent) => cloneElement(parent, { children }),
      children
    )}
  </>
);

export default ContextComposer;
