const BaseColors = {
  Black: '#000',
  White: '#fff',
  Gallery: '#ededed',
  Geyser: '#E3E3E3',
  LightGrey: '#fcfcfc',
  DarkerGrey: '#E0E0E0',
  DarkGrey: '#949494',
  PaleSky: '#6c757d',
  Danube: '#5890c3',
  SelectBlue: '#0099ff',
  LightBlue: '#2196F34C',
  DodgerBlue: '#2196f3',
  AzureRadiance: '#007bff',
  CornflowerBlue: '#4285f4',
  CornflowerBlueHover: '#3273df',
  CornflowerBlueClick: '#1958c1',
  Endeavour: '#0056b3',
  MidnightBlue: '#003964',
  Salmon: '#ff8663',
  Alabaster: '#f7f7f7',
  Carnation: '#f15b55',
  CarnationHover: '#ce0012',
  CarnationClick: '#a70715',
  Flamingo: '#eb5437',
  FlamingoHover: '#dc4529',
  FlamingoClick: '#b42a11',
  MilanoRed: '#b21c0c',
  Dolly: '#ffff8d',
  HokeyPokey: '#c0a42a',
  EnergyYellow: '#f6d55c',
  Supernova: '#4AA4FF',
  SupernovaHover: '#ffbb05',
  SupernovaClick: '#eea509',
  Atlantis: '#00c851',
  AtlantisHover: '#10ae50',
  AtlantisClick: '#0e8b41',
  NightGreen: '#71a113',
  PersianGreen: '#00aaa5',
  Lynch: '#607d8b',
  Shark: '#212529',
  Porcelai: '#eceff1',
  Sail: '#bcdffb',
  OrangePeel: '#ffa000',
  Success: '#00c851',
  CoralRed: '#ff3547',
  Red: '#D91616',
  ErrorRed: '#f44336',
  BridalHeath: '#fffaf0',
  Shamrock: '#2bbbad',
  Alto: '#e0e0e0',
};

const Colors = {
  ...BaseColors,
  ChathamsBlue: '#124977',
  Blumine: '#1a5383',
  BahamaBlue: '#01579B',
  Matisse: '#4AA4FF',
  MatisseHover: '#4AA4FF',
  MatisseClick: '#0c4471',
  SoftGrey: '#505050',
  HardGrey: '#383838',
};

export default Colors;
