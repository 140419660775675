import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '..';
import { User } from 'shared/types/auth';
import { Status } from 'shared/types/common';
import AuthService, { getAuthObject } from 'shared/services/AuthService';

export type AuthState = Status & {
  user?: User;
  isAuthenticated: boolean;
  isLoading: boolean;
};

const initialState: AuthState = {
  status: 'idle',
  user: getAuthObject(),
  isAuthenticated: Boolean(getAuthObject()),
  isLoading: false,
};

const {
  actions: { request, success, failure, clearUserAuthData },
  reducer,
} = createSlice({
  name: 'auth',
  initialState: initialState as AuthState,
  reducers: {
    request: state => ({
      ...state,
      status: 'pending',
      isLoading: true,
    }),

    // herehere

    success: (state, action: PayloadAction<User>) => ({
      ...state,
      status: 'resolved',
      user: action?.payload,
      isLoading: false,
      isAuthenticated: Boolean(action?.payload),
    }),

    failure: (state, action: PayloadAction<string>) => ({
      ...state,
      status: 'rejected',
      isLoading: false,
      error: action?.payload,
    }),
    clearUserAuthData: state => ({
      ...state,
      user: getAuthObject(),
      isAuthenticated: Boolean(getAuthObject()),
    }),
  },
});

export const login =
  (...params: Parameters<typeof AuthService.login>): AppThunk =>
  async dispatch => {
    try {
      dispatch(request());

      //debugger;
      const user = await AuthService.login(...params);
      console.log(user);

      //debugger;
      // herehere
      // set user
      dispatch(success(user));
    } catch (e) {
      dispatch(failure(e));

      throw e;
    }
  };

export { clearUserAuthData };

export default reducer;
