import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)`
  font: normal normal 500 16px/21px Roboto;
  color: #000000;
  letter-spacing: 0px;
  text-decoration: none;
  margin: 0 10px;
  display: flex;
  height: 100%;
  align-items: center;
  img {
    vertical-align: bottom;
    margin: 0 5px;
  }
`;
