import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { BASE_ROUTE } from './shared/navigation/appRoutes';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import baseTheme from './shared/styles/baseTheme';
import PublicApp from './public/PublicApp';

const themeToUse = createTheme(baseTheme);

const Routes: React.FC = () => {
  return (
    <ThemeProvider theme={themeToUse}>
      <CssBaseline />
      <Switch>
        <Route path={BASE_ROUTE}>
          <PublicApp />
        </Route>
        <Redirect to={BASE_ROUTE} />
      </Switch>
    </ThemeProvider>
  );
};

export default Routes;
