import { BASE_ROUTE } from './appRoutes';
import AuthService from '../services/AuthService';

export const NAVBAR_ITEMS = [
  {
    label: 'Летище',
    link: `${BASE_ROUTE}/`,
    icon: 'airplane_icon',
    exact: true,
  },
  {
    label: 'Транспортен план',
    link: `${BASE_ROUTE}/transport-plan`,
    icon: 'plan_icon',
    exact: true,
  },
  {
    label: 'Транспортни фирми',
    // link: `${BASE_ROUTE}/companies`,
    icon: 'plan_icon',
    exact: true,
    children: [
      {
        label: 'Транспортни фирми',
        icon: '',
        link: `${BASE_ROUTE}/companies`,
        exact: true,
      },
      {
        label: 'Камиони',
        icon: '',
        link: `${BASE_ROUTE}/companies/trucks`,
        exact: true,
      },
    ],
  },
  {
    label: 'Справки',
    link: `${BASE_ROUTE}/report-page`,
    icon: 'reports_icon',
    exact: true,
  },
  {
    label: 'Потребители',
    link: `${BASE_ROUTE}/users`,
    icon: 'user_icon',
    exact: true,
  },
  // {
  //   label: 'Дистрибутори',
  //   link: `${BASE_ROUTE}/distributors`,
  //   icon: 'distributors_icon',
  //   exact: true,
  // },
  {
    label: 'Log out',
    link: '#',
    icon: 'logout_icon',
    props: {
      onClick: () => AuthService.logout(true),
    },
  },
];
