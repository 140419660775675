import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import ContextComposer from './shared/components/ContextComposer';
import { Provider } from 'react-redux';
import store from 'shared/store';
import { useMount } from 'react-use';
import AuthService, {
  enableApiErrorInterceptor,
} from 'shared/services/AuthService';

enableApiErrorInterceptor();

const App: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useMount(async () => {
    await AuthService.authenticate();
    setIsLoaded(true);
  });
  return isLoaded ? (
    <>
      <ContextComposer
        contexts={[<Provider store={store} />, <BrowserRouter />]}
      >
        <Routes />
      </ContextComposer>
    </>
  ) : (
    <></>
  );
};

export default App;
