/* eslint-disable array-callback-return */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { pickBy, identity } from 'lodash';

import { combineApiUrl, combineConfig } from 'shared/utils/axios';
import { LoginResponse, AuthObject } from 'shared/types/auth';
import ApiService from 'shared/services/ApiService';
import { LOGIN_REQUEST_PATH } from './constants';
import { parseJwt } from '../utils/parseJwt';
import jwt_decode from 'jwt-decode';

const httpAuthenticationErrorCode = 401;
const httpPermissionRoleErrorCode = 403;

const localStorageTokenKey = 'token';
export const localStorageUserDataKey = 'userData';
export const localStorageUserID = 'userID';
export const localStorageRoleKey =
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export const getUserId = () => localStorage?.getItem(localStorageUserID);

export const setAuthObject = (authObject: AuthObject) => {
  if (typeof authObject === 'object' && !Array.isArray(authObject)) {
    //console.log(authObject);
    //.data.value.userToken
    const authObjectToSet = pickBy(authObject, identity);
    //const authObjectToSet = authObject.accessToken.value.userToken;
    /* tslint:disable */
    if (authObjectToSet) {
      //console.log(authObjectToSet.accessToken.data.data.value.userToken);
    }

    if (Object.keys(authObjectToSet).length) {
      localStorage.setItem(
        localStorageTokenKey,
        JSON.stringify(authObjectToSet.accessToken.data.data.value.userToken)
      );
    }
  }
};

export const getAuthObject = () => {
  const object = localStorage.getItem(localStorageTokenKey);

  return object ? (JSON.parse(object) as AuthObject) : undefined;
};

const clearAuthObject = () => {
  localStorage.clear();
};

const formatAuthorizationHeader = (tokeString: string) =>
  `Bearer ${tokeString}`;

//console.log(formatAuthorizationHeader);

let authRequestInterceptors: number | null = null;

const setAuthRequestInterceptors = () => {
  authRequestInterceptors = axios.interceptors.request.use(
    config => {
      const auth = getAuthObject();

      //console.log(auth);

      //if (auth && auth.accessToken) {
      if (auth) {
        //alert(1);
        //auth?.accessToken
        //config.headers.Authorization = formatAuthorizationHeader(auth);
        config.headers.Authorization = `Bearer ${auth}`;
      } else {
        alert(1);
      }

      return config;
    },
    error => Promise.reject(error)
  );
};

const removeAuthRequestInterceptors = () => {
  if (authRequestInterceptors !== null) {
    axios.interceptors.request.eject(authRequestInterceptors);
    authRequestInterceptors = null;
  }
};

export const enableApiRequestInterceptor = () => {
  axios.interceptors.request.use(request => {
    return request;
  });
};

export const enableApiErrorInterceptor = () => {
  axios.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
      let errorText = error?.response?.data?.apierror?.message;
      const subErrors: object[] | null =
        error?.response?.data?.apierror?.subErrors;
      const originalRequest: AxiosRequestConfig & { _retry?: boolean } =
        error?.config;
      const url = originalRequest?.url;
      const code = error?.response?.status;
      const isAuthError = code === httpAuthenticationErrorCode;
      const isPermissionRoleError = code === httpPermissionRoleErrorCode;
      const isRefreshTokenError = url && url.match(/auth\/refresh/gi);
      const isBlocked =
        error?.response?.data?.apierror?.code === 'BlockedIpException' &&
        localStorage.hasOwnProperty('token');

      if (isRefreshTokenError || isBlocked) {
        //AuthService.logout(true);
      }

      if (Array.isArray(subErrors) && subErrors.length > 0) {
        // tslint:disable-next-line:no-shadowed-variable
        subErrors.map((error, index) => {
          errorText = errorText.concat(
            `${index > 0 ? '\n' : '\n\n'}${
              // @ts-ignore
              error?.message
            }`
          );
        });
      }

      if (!errorText && error.message) {
        errorText = error.message;
      }

      // @ts-ignore
      if (
        error &&
        errorText &&
        error?.response?.data?.apierror?.code !== 'AccessTokenException' &&
        error?.response?.data?.Error?.StatusDescription === 'Conflict'
      ) {
        //console.log(error);
        //console.log(error.response);
        // @ts-ignore
        //console.log(error.response.data.Error.StatusDescription);

        // @ts-ignore
        toast.error(error.response.data.Error.Message);
      }

      if (
        errorText &&
        error?.response?.data?.apierror?.code !== 'AccessTokenException' &&
        !errorText.includes(
          'exception.access.token.verification.not.translated'
        ) &&
        !url?.includes('login-cep') &&
        !isAuthError &&
        !isPermissionRoleError &&
        error?.response?.data?.Error?.StatusDescription !== 'Conflict'
      ) {
        console.log(1, errorText);
        toast.error(errorText);
      }

      const isLogout = Boolean(url && url.match(/auth\/logout/gi));

      if (
        !isLogout &&
        isAuthError &&
        !error?.config?.url?.includes(LOGIN_REQUEST_PATH)
      ) {
        console.log(2);
        toast.error('Изтекла сесия');

        await AuthService.logout(true);

        // debugger;
        // console.log(!isLogout);
        // console.log(!isAuthError);
        // console.log(!error?.config?.url?.includes(LOGIN_REQUEST_PATH));
        // debugger;
        //await AuthService.logout(true);
      }
      if (
        !isLogout &&
        isPermissionRoleError &&
        !error?.config?.url?.includes(LOGIN_REQUEST_PATH)
      ) {
        console.log(3);
        toast.error('Нямате нужните права да извършите това действие');
      }

      throw error;
    }
  );
};

const AuthService = {
  authenticate: () => {
    const auth = getAuthObject();
    //if (auth?.accessToken) {
    if (auth) {
      setAuthRequestInterceptors();
    }
    return auth && auth.accessToken;
  },

  formatAuthObject: (accessToken: any) => ({
    accessToken,
  }),

  getToken: <R>(url: string, config?: AxiosRequestConfig) =>
    axios.get<R>(combineApiUrl(url), combineConfig(config)),

  login: async (username?: string, password?: string) => {
    try {
      const loginResponse = await ApiService.post<LoginResponse>(
        '/Login/Login',
        {
          username,
          password,
        }
      );

      if (!loginResponse) {
        throw new Error('Unknown error');
      }

      const tokenObject = AuthService.formatAuthObject(
        //loginResponse.headers['x-jwtoken']
        loginResponse
      );

      // if (loginResponse) {
      //   console.log(loginResponse.data.value.userToken);
      // }

      //console.log(tokenObject);

      setAuthObject(tokenObject);

      setAuthRequestInterceptors();

      //var decoded = jwt_decode(user.token);
      //const currentUserInfo = parseJwt(tokenObject?.accessToken);
      //debugger;
      // @ts-ignore
      const currentUserInfo = jwt_decode(
        tokenObject?.accessToken?.data.data.value.userToken
      );

      //debugger;

      // here
      //console.log(currentUserInfo);
      //const userID = currentUserInfo?.UserId;

      //debugger;
      // @ts-ignore
      const userID = currentUserInfo?.UserId;

      //console.log(userID);

      //debugger;

      if (userID) {
        localStorage.setItem(localStorageUserID, userID);

        localStorage.setItem(
          localStorageUserDataKey,
          JSON.stringify(currentUserInfo)
        );

        // window.location.reload();
      }

      //debugger;

      //return tokenObject;
      return { accessToken: currentUserInfo };
      //return { currentUserInfo };
      //return { ...currentUserInfo };
    } catch (e) {
      throw e;
    }
  },

  logout: async (reload?: boolean) => {
    try {
      const token: AuthObject = JSON.parse(
        localStorage.getItem(localStorageTokenKey) || ''
      );
      //console.log(token);
      //debugger;
      if (token.accessToken) {
        //debugger;
        //console.log(111);
        await ApiService.post('/auth/logout', {
          token: token.accessToken,
        });
      }
    } catch (error) {
      console.error(error);
    }
    clearAuthObject();
    removeAuthRequestInterceptors();
    if (reload) {
      window.location.reload();
    }
  },
};

export default AuthService;
