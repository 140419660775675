import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@material-ui/core';
import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography';
import Color from './color';

const headingBase: TypographyStyleOptions = {
  fontWeight: 'normal',
  fontFamily: 'Roboto',
};

const h6: TypographyStyleOptions = {
  fontSize: 14,
  ...headingBase,
};

const paragraph: TypographyStyleOptions = {
  fontSize: 12,
  ...headingBase,
};

const themeDefinition: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: '#4AA4FF',
      light: Color.Danube,
      dark: Color.Black,
    },
    secondary: {
      main: '#FFFFFF',
      light: Color.Salmon,
      dark: Color.MilanoRed,
    },
    warning: {
      main: Color.Supernova,
      light: Color.Dolly,
      dark: Color.HokeyPokey,
    },
    error: {
      main: Color.Carnation,
      light: Color.Salmon,
      dark: Color.MilanoRed,
    },
    success: {
      main: Color.Atlantis,
      light: Color.PersianGreen,
      dark: Color.NightGreen,
    },
  },
  typography: {
    h1: {
      fontSize: 40,
      ...headingBase,
      fontWeight: 500,
    },
    h2: {
      fontSize: 32,
      ...headingBase,
      fontWeight: 500,
    },
    h3: {
      fontSize: 28,
      ...headingBase,
      fontWeight: 500,
    },
    h4: {
      fontSize: 24,
      ...headingBase,
      fontWeight: 500,
    },
    h5: {
      fontSize: 20,
      ...headingBase,
      fontWeight: 500,
    },
    h6: {
      ...h6,
      fontWeight: 500,
    },
    subtitle1: h6,
    subtitle2: h6,
    body1: paragraph,
    body2: paragraph,
    button: {
      fontSize: 16,
      lineHeight: 'normal',
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
    },
    fontSize: 12,
    htmlFontSize: 10,
    // TODO: define adeqaute fallbacks, for h1-h6 too
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
};

const theme = createTheme(themeDefinition);

export default responsiveFontSizes(theme, { disableAlign: true });
