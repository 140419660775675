import { AxiosRequestConfig } from 'axios';
//import { getAuthObject } from 'shared/services/AuthService'

export const defaultConfig: AxiosRequestConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const combineConfig = (
  config?: AxiosRequestConfig
): AxiosRequestConfig => ({
  ...defaultConfig,
  ...config,
});

export const combineGisConfig = (
  config?: AxiosRequestConfig
): AxiosRequestConfig => ({
  ...defaultConfig,
  ...config,
});

export const createUrlCombiner = (base: string) => (url: string) =>
  `${base}${url}`;
// TODO: TEMP FIX remove ASAP
export const combineApiUrl = createUrlCombiner(
  process.env.REACT_APP_API_URL || ''
);
