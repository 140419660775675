import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {},
  body: {
    padding: '20px',
  },
  menuLogo: {
    maxWidth: '110px',
  },
  tabs: {
    marginTop: '30px',
    '& .MuiTab-labelIcon': {
      minHeight: 'auto',
      padding: '16px 0',
    },
  },
  tab: {
    marginRight: '5px',
    fontSize: '16px!important',
    opacity: '0.25',
    backgroundColor: '#FFFFFF',
    '&:last-of-type': {
      marginRight: '0',
    },
    '&.Mui-selected': {
      backgroundColor: '#4AA4FF',
      color: '#FFFFFF',
      '& img': {
        filter: 'none',
        opacity: 1,
      },
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
    },
    '& img': {
      margin: '0 6px 0 0!important',
      filter: 'invert(100%)',
      opacity: '0.6',
    },
  },
  subMenuContainer: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    '&:hover $menuBox': {
      display: 'flex',
    },
  },
  menuBox: {
    position: 'absolute',
    left: 0,
    top: '60px',
    width: '100%',
    background: 'white',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 75%)',
    '&:hover': {
      display: 'flex',
    },
    flexDirection: 'column',
  },
}));
