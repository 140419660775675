import { combineReducers } from 'redux';

import authReducer, { AuthState } from '../slices/auth';

export interface RootState {
	auth: AuthState;
}

export default combineReducers<RootState>({
	auth: authReducer,
});
