import React, { Fragment, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { APP_ROUTES, BASE_ROUTE } from 'shared/navigation/appRoutes';
import AsyncPage from 'shared/components/AsyncPage';
import { AppBar, Grid, IconButton, Toolbar } from '@material-ui/core';
import { NAVBAR_ITEMS } from 'shared/navigation/menu';
import { StyledNavLink } from 'shared/components/StyledNavLink';
import useStyles from '../pages/home/HomePage.styles';
import '../App.css';
import { AUTH_ROUTES } from '../shared/navigation/authRoutes';
import useAuth from 'shared/hooks/useAuth';
import LogoMenuIcon from 'shared/assets/images/logo_menu.png';
import {
  localStorageRoleKey,
  localStorageUserDataKey,
} from '../shared/services/AuthService';

import Dropdown from 'shared/components/Dropdown';

const RenderIfIsNotAuthenticated: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return !isAuthenticated ? <>{children}</> : <Redirect to={BASE_ROUTE} />;
};

const RenderIfIsAuthenticated: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <>{children}</> : <Redirect to="/login" />;
};

const PublicApp: React.FC = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();

  const loggedUserData: any =
    isAuthenticated && localStorage.getItem(localStorageUserDataKey);
  const loggedUserRole = JSON.parse(loggedUserData)[localStorageRoleKey];
  const isLoggedUserAdmin = loggedUserRole === 'UserAdmin';

  const [toggleDropdown, setToggleDropdown] = useState(false);
  // @ts-ignore
  const handleToggle = event => {
    event.preventDefault();
    event.stopPropagation();
    setToggleDropdown(prevState => !prevState);
    console.log(toggleDropdown);
  };
  const closeToggle = () => {
    setToggleDropdown(false);
  };

  return (
    <>
      {isAuthenticated && (
        <AppBar color="secondary" component="nav" position="static">
          <Toolbar>
            <Grid container justify="space-between">
              <IconButton edge="start" color="inherit" aria-label="menu">
                <img
                  className={classes.menuLogo}
                  src={LogoMenuIcon}
                  alt="menu"
                />
              </IconButton>
              <ul
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  margin: 0,
                }}
              >
                {NAVBAR_ITEMS.map((item: any, key: number) => {
                  const { prefix, link } = item;
                  const isParent = Boolean(item?.children?.length);

                  if (!isLoggedUserAdmin && link === '/users') {
                    return <Fragment />;
                  }
                  if (isParent) {
                    return (
                      <div className="link-with-dropdown">
                        <a
                          {...item?.props}
                          key={item.label}
                          onClick={handleToggle}
                        >
                          {item.icon && (
                            <img
                              src={`/images/icons/${item.icon}.svg`}
                              alt={item.icon}
                            />
                          )}
                          {item.label}
                        </a>

                        {toggleDropdown && (
                          <Dropdown
                            children={item.children}
                            closeToggle={closeToggle}
                          />
                        )}
                      </div>
                    );
                  }
                  if (isParent) {
                    return (
                      <div key={key} className={classes.subMenuContainer}>
                        <StyledNavLink
                          {...item?.props}
                          key={item.label}
                          {...{
                            isActive: match => {
                              return (
                                isParent
                                  ? match?.url.includes(prefix || '')
                                  : match?.url === link && match?.isExact
                              ) as boolean;
                            },
                          }}
                          // to={link}
                        >
                          {item.icon && (
                            <img
                              src={`/images/icons/${item.icon}.svg`}
                              alt={item.icon}
                            />
                          )}
                          {item.label}
                        </StyledNavLink>
                        <div className={classes.menuBox}>
                          {item?.children?.map((child: any) => {
                            const isParentChild = Boolean(
                              child?.children?.length
                            );
                            return (
                              <StyledNavLink
                                {...child?.props}
                                key={child.label}
                                style={{
                                  width: '100%',
                                  margin: '0',
                                  justifyContent: 'center',
                                  minHeight: '40px',
                                }}
                                {...{
                                  isActive: match => {
                                    return (
                                      isParentChild
                                        ? match?.url.includes(
                                            child.prefix || ''
                                          )
                                        : match?.url === child.link &&
                                          match?.isExact
                                    ) as boolean;
                                  },
                                }}
                                to={child.link}
                              >
                                {child.icon && (
                                  <img
                                    src={`/images/icons/${child.icon}.svg`}
                                    alt={child.icon}
                                  />
                                )}
                                {child.label}
                              </StyledNavLink>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                  return (
                    <StyledNavLink
                      {...item?.props}
                      key={item.label}
                      {...{
                        isActive: match => {
                          return (
                            isParent
                              ? match?.url.includes(prefix || '')
                              : match?.url === link && match?.isExact
                          ) as boolean;
                        },
                      }}
                      to={link}
                    >
                      {item.icon && (
                        <img
                          src={`/images/icons/${item.icon}.svg`}
                          alt={item.icon}
                        />
                      )}
                      {item.label}
                    </StyledNavLink>
                  );
                })}
              </ul>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <Switch>
        {AUTH_ROUTES.map(({ path, importLocation, name, ...rest }) => {
          return (
            <Route key={path} path={path} {...rest}>
              <RenderIfIsNotAuthenticated>
                <AsyncPage location={`pages/${importLocation}`} />
              </RenderIfIsNotAuthenticated>
            </Route>
          );
        })}

        <RenderIfIsAuthenticated>
          {APP_ROUTES.map(({ path, importLocation, name, ...rest }) => {
            return (
              <Route key={path} path={path} {...rest}>
                <AsyncPage location={`pages/${importLocation}`} />
              </Route>
            );
          })}
        </RenderIfIsAuthenticated>

        {/*<Redirect to={BASE_ROUTE} />*/}
      </Switch>
    </>
  );
};

export default PublicApp;
