import axios, { AxiosRequestConfig } from 'axios';

import { combineApiUrl, combineConfig } from 'shared/utils/axios';

const ApiService = {
  get: <R>(url: string, config?: AxiosRequestConfig) =>
    axios.get<R>(combineApiUrl(url), combineConfig(config)),

  post: <R, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) =>
    axios.post<R>(combineApiUrl(url), data, combineConfig(config)),

  put: <R, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig) =>
    axios.put<R>(combineApiUrl(url), data, combineConfig(config)),

  delete: <R>(
    url: string,
    //data?: D,
    config?: AxiosRequestConfig
  ) => axios.delete<R>(combineApiUrl(url), combineConfig(config)),
};

export default ApiService;
