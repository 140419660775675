import { useMemo } from 'react';
import equal from 'fast-deep-equal';
import { useSelector, useDispatch } from 'react-redux';

import { login } from 'shared/store/slices/auth';
import { RootState } from 'shared/store/reducers/root';

const useAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }: RootState) => auth, equal);

  return useMemo(
    () => ({
      ...auth,
      login: (...params: Parameters<typeof login>) =>
        dispatch(login(...params)),
    }),
    [auth, dispatch]
  );
};

export default useAuth;
