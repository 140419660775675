import { Route } from '../types/route';

export const BASE_ROUTE = '';

export const APP_ROUTES: Route[] = [
  {
    path: `${BASE_ROUTE}/transport-plan`,
    name: 'Transport Plan Page',
    exact: true,
    importLocation: 'transportPlan/TransportPlanPage',
  },
  {
    path: `${BASE_ROUTE}/users`,
    name: 'Users Page',
    exact: true,
    importLocation: 'users/UsersPage',
  },
  {
    path: `${BASE_ROUTE}/companies/trucks`,
    name: 'Trucks Page',
    exact: true,
    importLocation: 'trucks/TrucksPage',
  },
  {
    path: `${BASE_ROUTE}/companies`,
    name: 'Transport Companies Page',
    exact: true,
    importLocation: 'transportCompanies/TransportCompanies',
  },
  {
    path: `${BASE_ROUTE}/`,
    name: 'Port Page',
    exact: true,
    importLocation: 'home/HomePage',
  },
  {
    path: `${BASE_ROUTE}/report-page`,
    name: 'Report Page',
    exact: true,
    importLocation: 'report/ReportPage',
  },
];
