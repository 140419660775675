import { Route } from 'shared/types/route';

export const AUTH_BASE_ROUTE = '';

export const AUTH_ROUTES: Route[] = [
  {
    exact: true,
    name: 'Login',
    path: `${AUTH_BASE_ROUTE}/login`,
    importLocation: 'login/LoginPage',
    sliceLocation: '',
  },
];
